// Entry point for the build script in your package.json
//= require jquery3
//= require popper
//= require bootstrap-sprockets
import 'bootstrap'
import 'jquery'
import "vega"
import "vega-lite"
import "vega-embed"

window.dispatchEvent(new Event("vega:load"))

import embed from "vega-embed"

window.vegaEmbed = embed
window.dispatchEvent(new Event("vega:load"))

document.addEventListener('turbolinks:load', () => {
    console.log(1)
    console.log("Hello from application.js")
    console.log($)
})


